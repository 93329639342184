import React, { createContext, useState, useEffect } from "react"

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([])

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || []
    setCart(storedCart)
  }, [])

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart))
  }, [cart])

  // Funkcija prekei pridėti arba pašalinti
  const toggleCartItem = product => {
    setCart(prevCart => {
      const isInCart = prevCart.some(item => item.id === product.id)

      if (isInCart) {
        // Jei prekė jau yra krepšelyje, pašaliname
        return prevCart.filter(item => item.id !== product.id)
      } else {
        // Jei prekės nėra krepšelyje, pridedame
        return [...prevCart, { ...product, quantity: 1 }]
      }
    })
  }

  return (
    <CartContext.Provider value={{ cart, toggleCartItem }}>
      {children}
    </CartContext.Provider>
  )
}
