import React from "react"
import { CartProvider } from "./src/context/CartContext"
import "./src/styles/styles.css"

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)
  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]))
  }, 0)
  return false
}

export const wrapRootElement = ({ element }) => (
  <CartProvider>{element}</CartProvider>
)
